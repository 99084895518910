import './KISANOLHC.css';

export var LightHealthConnectorNamespace = {
    port: -1,
    previousPort: null,
    portRange: new Array(2375, 2376, 2377, 2378, 2379, 2380),
    portFindingInterval: null,
    defaults: {
        portFindingTimeInterval: {
            long: 5000,
            short: 1000
        }
    },
    portFindingTimeInterval: 5000,
    kisanoClientUrl: null,
    username: undefined,

    isFindingKisanoClient: false,

    lightHealthConnectorConnectedCallbacks: [],
    lightHealthConnectorDisconnectedCallbacks: [],

    errorCallback: undefined,

    lightHealthConnectorBar: "#lightHealthConnectorBar",

    addLightHealthConnectorConnectedCallback: function(callback) {
        LightHealthConnectorNamespace.lightHealthConnectorConnectedCallbacks.push(callback);
    },

    addLightHealthConnectorDisconnectedCallback: function(callback) {
        LightHealthConnectorNamespace.lightHealthConnectorDisconnectedCallbacks.push(callback);
    },

    isLightHealthConnectorConnected: function() {
        return LightHealthConnectorNamespace.port > -1;
    },

    getTransferStatusForExternalIdentifier: function(siteId, externalIdentifierType, externalIdentifier) {
        return LightHealthConnectorNamespace.callLHCMethod("getTransferStatus", {
            siteId: siteId,
            externalIdentifierType: externalIdentifierType,
            externalIdentifier: externalIdentifier
        });
    },

    lightHealthConnectorConnected: function (data) {
        console.log("LightHealthConnector connected");

        var $lightHealthConnectorBar = $(LightHealthConnectorNamespace.lightHealthConnectorBar);

        var $lightHealthConnectorConnectivity = $lightHealthConnectorBar.find('.connectivity');

        var usernameMatches = data.username !== undefined && data.username === LightHealthConnectorNamespace.username;

        $(LightHealthConnectorNamespace.lightHealthConnectorBar +" .connectivity.nok").off('click');

        $lightHealthConnectorConnectivity
            .text(data.username !== undefined ? data.username : LightHealthConnectorNamespace.port)
            .addClass(usernameMatches ? 'ok' : 'warning')
            .removeClass('nok');

        $lightHealthConnectorBar.find('.help').hide();

        for(var i = 0; i < LightHealthConnectorNamespace.lightHealthConnectorConnectedCallbacks.length; i++) {
            LightHealthConnectorNamespace.lightHealthConnectorConnectedCallbacks[i](data);
        }
    },

    lightHealthConnectorDisconnected: function () {
        console.log("LightHealthConnector disconnected");

        var $lightHealthConnectorBar = $(LightHealthConnectorNamespace.lightHealthConnectorBar);

        var $lightHealthConnectorConnectivity = $lightHealthConnectorBar.find('.connectivity');
        $lightHealthConnectorConnectivity
            .removeClass('ok')
            .removeClass('warning')
            .addClass('nok');

        $(LightHealthConnectorNamespace.lightHealthConnectorBar +" .connectivity.nok").click(LightHealthConnectorNamespace.loadKisanoClient);

        for(var i = 0; i < LightHealthConnectorNamespace.lightHealthConnectorDisconnectedCallbacks.length; i++) {
            LightHealthConnectorNamespace.lightHealthConnectorDisconnectedCallbacks[i]();
        }
    },

    loadKisanoClient: function() {
        window.open(LightHealthConnectorNamespace.kisanoClientUrl);
    },

    processLightHealthConnectorConnection: function(connected, data) {
        if(connected && LightHealthConnectorNamespace.port !== LightHealthConnectorNamespace.previousPort) {
            LightHealthConnectorNamespace.previousPort = LightHealthConnectorNamespace.port;
            LightHealthConnectorNamespace.lightHealthConnectorConnected(data);
        } else if(!connected && LightHealthConnectorNamespace.port !== LightHealthConnectorNamespace.previousPort) {
            LightHealthConnectorNamespace.previousPort = LightHealthConnectorNamespace.port;
            LightHealthConnectorNamespace.lightHealthConnectorDisconnected();
        }
    },

    startFindingKisanoClient: function(i) {
        if(LightHealthConnectorNamespace.isFindingKisanoClient === true) {
            console.log("Already finding KisanoClient");
            return;
        }

        LightHealthConnectorNamespace.isFindingKisanoClient = true;

        LightHealthConnectorNamespace.findKisanoClient(i);
    },

    /**
     *  Finds the listening port recursively.
     *  When found, save the value into a global variable.
     *  @argument i int
     */
    findKisanoClient: function (i) {
        if (i < 0 || i >= LightHealthConnectorNamespace.portRange.length) {
            LightHealthConnectorNamespace.isFindingKisanoClient = false;
            LightHealthConnectorNamespace.port = -1;
            LightHealthConnectorNamespace.portFindingTimeInterval = LightHealthConnectorNamespace.defaults.portFindingTimeInterval.long;
            return;
        }

        console.log("Searching for KisanoClient on port: "+ LightHealthConnectorNamespace.portRange[i]);

        // TODO add siteId to handle case of same user already logged in on another site
        $.ajax({
            url: 'https://localhost.kisanogroup.com:' + LightHealthConnectorNamespace.portRange[i] + '/ApplicationDiscovery?action=areYouKisanoClient'+ (LightHealthConnectorNamespace.username !== undefined ? '&username='+ LightHealthConnectorNamespace.username : ''),
            timeout: 50000,
            success: function (data) {
                if (data.result === 'KisanoClient') {
                    console.log("Found KISANO Client listening on port: " + LightHealthConnectorNamespace.portRange[i]);
                    LightHealthConnectorNamespace.isFindingKisanoClient = false;

                    LightHealthConnectorNamespace.port = LightHealthConnectorNamespace.portRange[i];
                    LightHealthConnectorNamespace.processLightHealthConnectorConnection(true, data);
                    LightHealthConnectorNamespace.portFindingTimeInterval = LightHealthConnectorNamespace.defaults.portFindingTimeInterval.long;
                    return;
                }

                var findNext = (i + 1) < LightHealthConnectorNamespace.portRange.length;
                console.log("Something is listening on port " + LightHealthConnectorNamespace.portRange[i] + " but it's not the KISANO Client." + (findNext ? "Trying " + LightHealthConnectorNamespace.portRange[i + 1] : " All ports were tested :("));
                if (findNext) {
                    LightHealthConnectorNamespace.findKisanoClient(++i);
                } else {
                    LightHealthConnectorNamespace.isFindingKisanoClient = false;
                    LightHealthConnectorNamespace.port = -1;
                    LightHealthConnectorNamespace.processLightHealthConnectorConnection(false);
                }
            },
            error: function (xOptions, textStatus) {
                var findNext = (i + 1) < LightHealthConnectorNamespace.portRange.length;
                console.log("Tested port " + LightHealthConnectorNamespace.portRange[i] + " but it's not the KISANO Client." + (findNext ? "Trying " + LightHealthConnectorNamespace.portRange[i + 1] : " All ports were tested :("));
                if (findNext) {
                    LightHealthConnectorNamespace.findKisanoClient(++i);
                } else {
                    LightHealthConnectorNamespace.isFindingKisanoClient = false;
                    LightHealthConnectorNamespace.port = -1;
                    LightHealthConnectorNamespace.processLightHealthConnectorConnection(false);
                }
            }
        });
    },

    callLHCMethod: function(methodName, parameters, callback) {
//        if(!LightHealthConnectorNamespace.isLightHealthConnectorConnected())
//            return;

        var url = "https://localhost.kisanogroup.com:" + LightHealthConnectorNamespace.port + "/" + methodName;

        Object.keys(parameters).forEach(key => {
            parameters[key] = typeof parameters[key] === 'object' ? JSON.stringify(parameters[key]) : parameters[key];
            return key;
        });

        $.ajax({
            type: 'POST',
            url: url,
            data: parameters,
            dataType: 'json',
            contentType: 'application/x-www-form-urlencoded',
            crossDomain: true,
            beforeSend: function(xhr){
                xhr.withCredentials = true;
            },
            success: function (data) {
                if(typeof callback === 'function')
                    callback(data);
            },
            error: LightHealthConnectorNamespace.errorCallback
        });
    },

    init: function() {
        var mayStartLHC = new Boolean($("body").data("maystartlhc")).valueOf();

        if(mayStartLHC) {
            LightHealthConnectorNamespace.kisanoClientUrl = $("body").data('kisanoclienturl');

            var $lightHealthConnectorBar = $(LightHealthConnectorNamespace.lightHealthConnectorBar);

            var $lightHealthConnectorConnectivity = $lightHealthConnectorBar.find('.connectivity');

            LightHealthConnectorNamespace.username = $lightHealthConnectorConnectivity.data('loggedinusername');

            LightHealthConnectorNamespace.startFindingKisanoClient(0);
            LightHealthConnectorNamespace.portFindingInterval = setInterval(function () {
                if (LightHealthConnectorNamespace.port === -1) {
                    LightHealthConnectorNamespace.startFindingKisanoClient(0);
                } else {
                    LightHealthConnectorNamespace.startFindingKisanoClient(LightHealthConnectorNamespace.portRange.indexOf(LightHealthConnectorNamespace.port));
                }
            }, LightHealthConnectorNamespace.portFindingTimeInterval);

            var $lightHealthConnectorBar = $(LightHealthConnectorNamespace.lightHealthConnectorBar);
            $lightHealthConnectorBar.show();
        }
    }
};

$(document).ready(function () {
    LightHealthConnectorNamespace.init();
});
